import {
  IconsNames,
  YouWillLearnType,
} from 'components/atoms';
import { PATHS } from 'router/config';

export enum EducationForm {
  online = 'Дистанционный курс',
  offline = 'Офлайн курс',
}

export enum FooterCategoryNames {
  programming = 'Программирование',
  testing = 'Тестирование',
  design = 'Дизайн',
  management = 'Менеджмент',
  analysis = 'Бизнес-анализ',
  hr = 'HR',
}

export enum Courses {
  qa_csharp = 'qa_csharp',
  qa_python = 'qa_python',
  qa_java = 'qa_java',
  qa_js = 'qa_js',
}

export const CoursesPathToKey = {
  'qa_csharp': Courses.qa_csharp,
  'qa_python': Courses.qa_python,
  'qa_java': Courses.qa_java,
  'qa_js': Courses.qa_js,
}

export enum Technologies {
  python = 'python',
  java = 'java',
  javaScript = 'javaScript',
  html = 'html',
  css = 'css',
  flask = 'flask',
  gitLab = 'gitLab',
  pyCharm = 'pyCharm',
  cPlusPlus = 'cPlusPlus',
  fastApi = 'fastApi',
}

export enum TechnologiesNames {
  python = 'Python',
  java = 'Java',
  javaScript = 'Java Script',
  html = 'HTML',
  css = 'CSS',
  flask = 'Flask',
  gitLab = 'GitLab',
  pyCharm = 'PyCharm',
  cPlusPlus = 'C++',
  fastApi = 'FastApi',
}

export interface ICourse {
  key: Courses;
  categoryName: 'тестирование';
  courseName: string;
  startDate: string;
  discountPrice: string;
  oldPrice: string;
  priceDiff: string;
  perMonth: string;
  img: IconsNames;
  link: string;
  educationFrom: EducationForm;
  duration: string;
  weWillTeach: string;
  youWillLearn: YouWillLearnType[];
  futureKnowledge: string;
  // technologies: Technologies[];
  futureSalaryTitle: string;
  salary: string;
  programText: string;
  academyHours: string;
  lessons: string[];
}

export const AllCourses: ICourse[] = [
  {
    key: Courses.qa_csharp,
    img: IconsNames.cs_qa_csharp,
    link: PATHS.qa_csharp,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: 'тестирование',
    courseName: 'Автоматизированное тестирование на C#',
    startDate: '15 августа',
    discountPrice: '52 000 ₽',
    oldPrice: '72 000 ₽',
    priceDiff: '-20 000 ₽',
    perMonth: '2 889',
    duration: '5 месяцев',
    weWillTeach: 'Научим автоматизированному тестированию на C# за 5 месяцев.',
    futureKnowledge: 'Automation QA Engineer разрабатывает автоматические тесты для нахождения уязвимостей и проверки ПО.    На курсе QA Engineer ты научишься писать автотесты для web-приложений и REST API. Познакомишься с современными тестовыми фреймворками, научишься создавать тестовую документацию, анализировать результаты тестов и составлять отчеты по ним.',
    youWillLearn: [
      {
        id: 1,
        title: 'Создавать автотесты',
        text: 'Для тестирования web-приложений и REST API, используя лучшие практики в индустрии',
      },
      {
        id: 2,
        title: 'Запускать тесты',
        text: 'Используя инструменты Continious Integration',
      },
      {
        id: 3,
        title: 'Работать с Selenium',
        text: 'Научишься работать с Selenium Webdriver на продвинутом уровне',
      },
      {
        id: 4,
        title: 'Писать документацию',
        text: 'Напишешь грамотную документацию и отчет тестирования',
      },
    ],
    futureSalaryTitle: 'Зарплата тестировщика на C#',
    salary: '65 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '140 академических часов',
    lessons: [
      '1. Введение в .NET и C#. Репозитории',
      '2. Введение в ООП. Обобщения. Исключения. Коллекции',
      '3. Введение в тестирование ПО',
      '4. Введение в автоматизацию тестирования',
      '5. Selenium WebDriver',
      '6. MStest, Xunit, Nunit. Репортинг',
      '7. GOF Patterns и Best Practices. Continuous integration.',
      '8. Подходы к построению тестов',
      '9. Автоматизация REST API. Работа с базой данных',
      '10. Консультация по дипломному проекту. Тестовое задание',
      '11. Онлайн-тренинг "Трудоустройство в IT"',
      '12. Защита дипломного проекта',
    ],
  },
  {
    key: Courses.qa_python,
    img: IconsNames.cs_qa_python,
    link: PATHS.qa_python,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: 'тестирование',
    courseName: 'Автоматизированное тестирование на Python',
    startDate: '20 августа',
    discountPrice: '49 000 ₽',
    oldPrice: '69 000 ₽',
    priceDiff: '-20 000 ₽',
    perMonth: '2 722',
    duration: '4 месяца',
    weWillTeach: 'Научим автоматизированному тестированию на Python за 4 месяца.',
    futureKnowledge: 'Automation QA Engineer разрабатывает автоматические тесты для нахождения уязвимостей и проверки ПО. На курсе QA Engineer ты научишься писать автотесты для web-приложений и REST API, создавать документацию и грамотную отчетность.',
    youWillLearn: [
      {
        id: 1,
        title: 'Создавать автотесты',
        text: 'Для тестирования web-приложений и REST API, используя лучшие практики в индустрии',
      },
      {
        id: 2,
        title: 'Запускать тесты',
        text: 'Используя инструменты Continious Integration',
      },
      {
        id: 3,
        title: 'Работать с Selenium, файлами и базами данных',
        text: 'Научишься работать с Selenium Webdriver, файлами и базами данных на продвинутом уровне',
      },
      {
        id: 4,
        title: 'Писать документацию',
        text: 'Напишешь грамотную документацию и отчет тестирования',
      },
    ],
    futureSalaryTitle: 'Зарплата тестировщика на Python',
    salary: '65 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '132 академических часа',
    lessons: [
      '1. Введение в алгоритмизацию и экосистему Python',
      '2. Основы тестирования ПО',
      '3. Введение в Linux',
      '4. Модульное тестирование',
      '5. Введение в автоматизацию тестирования. Selenium WebDriver',
      '6. REST API. Базы данных. Docker',
      '7. Консультация по дипломному проекту и тестовое задание',
      '8. Онлайн-тренинг "Трудоустройство в IT"',
      '9. Защита дипломного проекта',
    ],
  },
  {
    key: Courses.qa_js,
    img: IconsNames.cs_qa_js,
    link: PATHS.qa_js,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: 'тестирование',
    courseName: 'Автоматизированное тестирование на JavaScript',
    startDate: '20 августа',
    discountPrice: '49 000 ₽',
    oldPrice: '69 000 ₽',
    priceDiff: '-20 000 ₽',
    perMonth: '2 722',
    duration: '4 месяца',
    weWillTeach: 'Научим автоматизированному тестированию на JavaScript за 4 месяца.',
    futureKnowledge: 'Automation QA Engineer разрабатывает автоматические тесты для нахождения уязвимостей и проверки ПО. На курсе QA Engineer ты научишься писать автотесты для web-приложений и REST API, создавать документацию и грамотную отчетность.',
    youWillLearn: [
      {
        id: 1,
        title: 'Создавать и запускать автотесты',
        text: 'Для тестирования web-приложений и REST API, используя инструменты Continious Integration',
      },
      {
        id: 2,
        title: 'Работать с фреймворками',
        text: 'Научишься работать с тестовыми фреймворками для модульного и API тестирования',
      },
      {
        id: 3,
        title: 'Работать с Selenium и другим софтом',
        text: 'Научишься работать с Selenium Webdriver, WebDriverIO, Cypress и Playwright на продвинутом уровне',
      },
      {
        id: 4,
        title: 'Писать документацию',
        text: 'Напишешь грамотную документацию и отчет тестирования',
      },
    ],
    futureSalaryTitle: 'Зарплата тестировщика на JavaScript',
    salary: '65 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '124 академических часа',
    lessons: [
      '1. Введение в Typescript',
      '2. Введение в тестирование ПО',
      '3. Введение в автоматизацию тестирования',
      '4. Тестирование API. Selenium',
      '5. PageObjects. WebDriverIO. Playwright. Cypress',
      '6. Работа с базой данных. CI/CD',
      '7. Консультация по дипломному проекту',
      '8. Интервью',
      '9. Онлайн-тренинг "Трудоустройство в IT"',
      '10. Защита дипломного проекта',  
    ],
  },
  {
    key: Courses.qa_java,
    img: IconsNames.cs_qa_java,
    link: PATHS.qa_java,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: 'тестирование',
    courseName: 'Автоматизированное тестирование на Java',
    startDate: '22 августа',
    discountPrice: '52 000 ₽',
    oldPrice: '72 000 ₽',
    priceDiff: '-20 000 ₽',
    perMonth: '2 889',
    duration: '4,5 месяца',
    weWillTeach: 'Научим автоматизированному тестированию на Java за 4,5 месяца.',
    futureKnowledge: 'Automation QA Engineer разрабатывает автоматические тесты для нахождения уязвимостей и проверки ПО. На курсе QA Engineer ты научишься писать автотесты для web-приложений и REST API, создавать документацию и грамотную отчетность.',
    youWillLearn: [
      {
        id: 1,
        title: 'Создавать автотесты',
        text: 'Для тестирования web-приложений и REST API, используя лучшие практики в индустрии',
      },
      {
        id: 2,
        title: 'Запускать тесты',
        text: 'Используя инструменты Continious Integration',
      },
      {
        id: 3,
        title: 'Работать с Selenium',
        text: 'Научишься работать с Selenium Webdriver на продвинутом уровне',
      },
      {
        id: 4,
        title: 'Писать документацию',
        text: 'Напишешь грамотную документацию и отчет тестирования',
      },
    ],
    futureSalaryTitle: 'Зарплата тестировщика на Java',
    salary: '65 000 ₽',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '152 академических часа',
    lessons: [
      '1. Введение в Java',
      '2. Введение в тестирование ПО',
      '3. Введение в автоматизацию тестирования',
      '4. Selenium WebDriver. JUnit, TestNG. Maven',
      '5. GOF Patterns и Best Practices. Continuous integration – CircleCI',
      '6. Автоматизация REST API. Работа с базой данных',
      '7. Консультация по дипломному проекту',
      '8. Интервью',
      '9. Онлайн-тренинг "Трудоустройство в IT"',
      '10. Защита дипломного проекта',
    ],
  },
];
