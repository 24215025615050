import React, { memo, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import {
  CourseFeatures,
  CourseProgram,
  CourseStatistics,
  Icon,
  InfoCard,
  InfoCardsTablet,
  StickyCounter,
  YouWillLearn,
} from 'components/atoms';
import { MainForm } from 'components/molecules';
import { AllCourses, CoursesPathToKey, ICourse } from 'context';
import {
  ColorService,
  desktop,
  getFontFamily,
  laptop_small,
  tablet,
} from 'services';

export const Course = memo(() => {
  const { courseName } = useParams();
  const [courseData, setCourseData] = useState<ICourse | null>(null);

  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (courseName) {
      setCourseData(
        AllCourses.find(
          ({ key }) =>
            key ===
            CoursesPathToKey[courseName as keyof typeof CoursesPathToKey],
        ) || null,
      );
    }
  }, [courseName]);

  return (
    <Container>
      <Content>
        {courseData && (
          <>
            <TermsInfoWrapper>
              <IconWrapper>
                <StyledIcon name={courseData.img} />
              </IconWrapper>

              <div>
                <EducationForm>
                  {courseData.educationFrom} — {courseData.duration}
                </EducationForm>
                <Start>Старт - {courseData.startDate}</Start>
              </div>
            </TermsInfoWrapper>
            <CourseTitle>{courseData.courseName}</CourseTitle>
            <WillTeach>{courseData.weWillTeach}</WillTeach>

            <CourseFeatures />

            <BlockWrapper>
              <StyledInfoCardsTablet />
            </BlockWrapper>

            <BlockWrapper>
              <BlockTitle>Кем ты станешь</BlockTitle>
              <Text>{courseData.futureKnowledge}</Text>
            </BlockWrapper>

            <BlockWrapper>
              <BlockTitle>Чему ты научишься</BlockTitle>
              <YouWillLearn youWillLearn={courseData.youWillLearn} />
            </BlockWrapper>

            <BlockWrapper>
              <Salary>
                <SalaryBlockTitle>
                  {courseData.futureSalaryTitle}
                </SalaryBlockTitle>
                <SalaryWrap>
                  <SalaryText>
                    После курса студенты в среднем зарабатывают
                  </SalaryText>
                  <SalaryNumber>{courseData.salary}</SalaryNumber>
                </SalaryWrap>
              </Salary>
            </BlockWrapper>

            <BlockWrapper>
              <ProgramBlockTitle>Программа</ProgramBlockTitle>
              <CourseProgram
                hours={courseData.academyHours}
                text={courseData.programText}
                lessons={courseData.lessons}
              />
            </BlockWrapper>
          </>
        )}

        <CourseStatistics />
      </Content>

      <FormWrapper ref={formRef}>
        <StyledMainForm
          hasDiscountInfo
          discountPrice={courseData?.discountPrice}
          oldPrice={courseData?.oldPrice}
          perMonth={courseData?.perMonth}
        />
        <StyledInfoCard />
      </FormWrapper>

      <StickyCounter
        formRef={formRef}
        hasDiscountInfo
        discountPrice={courseData?.discountPrice}
        oldPrice={courseData?.oldPrice}
        perMonth={courseData?.perMonth}
      />
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: -32px;

  ${desktop} {
    flex-direction: row;
    gap: 54px;
    margin-top: 0;
  }
`;

const Content = styled.div``;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 64px;

  ${tablet} {
    margin-top: 80px;
  }

  ${desktop} {
    position: -webkit-sticky;
    position: sticky;
    align-self: flex-start;
    top: 54px;
    margin-top: 0;
  }
`;

const StyledMainForm = styled(MainForm)`
  ${desktop} {
    width: 414px;
  }
`;

const StyledInfoCard = styled(InfoCard)`
  display: none;

  ${desktop} {
    display: unset;
    width: 414px;
  }
`;

const TermsInfoWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
  align-items: center;

  ${tablet} {
    gap: 24px;
  }
`;

const IconWrapper = styled.div`
  background-color: ${ColorService.WHITE};
  width: 96px;
  height: 96px;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  flex-shrink: 0;
`;

const StyledIcon = styled(Icon)`
  height: 78%;
  width: 148%;
  object-fit: contain;
  align-self: flex-end;
  position: absolute;
  bottom: -2px;
  right: -20%;
`;

const EducationForm = styled.p`
  font-family: ${getFontFamily('bold')};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: ${ColorService.GRAY};
  margin-bottom: 8px;
`;

const Start = styled.p`
  color: ${ColorService.MAIN_BLACK};
`;

const CourseTitle = styled.h1`
  font-size: 32px;
  line-height: 44px;
  font-family: ${getFontFamily('bold')};
  margin-bottom: 12px;

  ${tablet} {
    font-size: 54px;
    line-height: 62px;
  }
`;

const WillTeach = styled.p`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 40px;
`;

const StyledInfoCardsTablet = styled(InfoCardsTablet)`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${tablet} {
    flex-direction: row;
  }

  ${desktop} {
    display: none;
  }
`;

const BlockWrapper = styled.div`
  margin-bottom: 64px;

  ${tablet} {
    margin-bottom: 80px;
  }
`;

const BlockTitle = styled.h2`
  font-size: 28px;
  line-height: 36px;
  font-family: ${getFontFamily('bold')};
  margin-bottom: 24px;

  ${tablet} {
    font-size: 32px;
    line-height: 44px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 24px;
`;

const Salary = styled.div`
  background-color: ${ColorService.MAIN_BLACK};
  border-radius: 24px;
  padding: 32px;

  ${tablet} {
    padding: 40px;
  }
`;

const SalaryBlockTitle = styled(BlockTitle)`
  color: ${ColorService.WHITE};
  margin-bottom: 32px;
  ${tablet} {
    max-width: 456px;
    margin-bottom: 40px;
  }
  ${desktop} {
    margin-bottom: 48px;
  }
`;

const SalaryText = styled(Text)`
  max-width: 210px;
`;

const SalaryWrap = styled.div`
  color: ${ColorService.WHITE};
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  ${desktop} {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  ${laptop_small} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const SalaryNumber = styled.p`
  font-family: ${getFontFamily('bold')};
  font-size: 48px;
  line-height: 54px;
  color: ${ColorService.YELLOW};

  ${tablet} {
    text-align: right;
    max-width: 300px;
    font-size: 54px;
    line-height: 62px;
  }
`;

const ProgramBlockTitle = styled(BlockTitle)`
  margin-bottom: 16px;
`;
