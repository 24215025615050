import React, { memo } from 'react';

import * as Icons from './icons';

export enum IconsNames {
  close = 'close',
  logo = 'logo',
  phone = 'phone',
  mir = 'mir',
  maestro = 'maestro',
  mastercard = 'mastercard',
  visa = 'visa',
  telegram_yellow = 'telegram_yellow',
  pin = 'pin',
  wallet = 'wallet',
  suitcase = 'suitcase',
  cs_qa_js = 'cs_qa_js',
  cs_qa_python = 'cs_qa_python',
  cs_qa_java = 'cs_qa_java',
  cs_qa_csharp = 'cs_qa_csharp',
  star = 'star',
  checkMark = 'checkMark',
  python = 'python',
  java = 'java',
  javaScript = 'javaScript',
  html = 'html',
  css = 'css',
  flask = 'flask',
  gitLab = 'gitLab',
  pyCharm = 'pyCharm',
  cPlusPlus = 'cPlusPlus',
  fastApi = 'fastApi',
  hh = 'hh',
}

export interface IIconProps {
  name: IconsNames;
  width?: number | string;
  height?: number | string;
  color?: string;
  className?: string;
}

const IconsMap: { [key: string]: any } = Icons;

export const Icon = memo(
  ({ name, width = '100%', height = '100%', color, className }: IIconProps) => {
    const IconComponent = IconsMap[name];

    return (
      <IconComponent
        width={width}
        height={height}
        fill={color}
        className={className}
      />
    );
  },
);
