import React, { memo, useRef } from 'react';

import styled from 'styled-components';

import {
  InfoCard,
  TMSFeatures,
  WhoIsQA,
  StickyCounter,
} from 'components/atoms';
import { Courses, MainForm } from 'components/molecules';

import { desktop, getFontFamily, tablet, ColorService } from 'services';

export const Main = memo(() => {
  const formRef = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <Content>
        <MainInfoWrapper>
          <Title>
            Освой профессию Automation QA Engineer вместе с TeachMeSkills
          </Title>
          <DiscountWrapper>
            <Warning>-20 000 ₽</Warning>
            <Subtitle>при бронировании места до 20 августа 2024 года.</Subtitle>
          </DiscountWrapper>
          <AdditionalText>
            Начать обучение можно будет в любой группе в течение года. Вносить
            оплату при бронировании места не нужно.
          </AdditionalText>
        </MainInfoWrapper>

        <ContentForm ref={formRef}>
          <MainForm />
        </ContentForm>

        <WhoIsQA />
        <Courses />
        <TMSFeatures />
      </Content>
      <FormWrapper>
        <MainForm />
        <InfoCard />
      </FormWrapper>

      <StickyCounter formRef={formRef} />
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  ${desktop} {
    flex-direction: row;
    gap: 32px;
  }
`;

const Content = styled.div``;

const MainInfoWrapper = styled.div`
  margin: 0 0 64px;

  ${tablet} {
    margin: 0 0 80px;
  }
`;

const Title = styled.h1`
  font-family: ${getFontFamily('bold')};
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 40px;

  ${tablet} {
    font-size: 48px;
    line-height: 56px;
  }

  ${desktop} {
    font-size: 54px;
    line-height: 65px;
  }
`;

const DiscountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;

  ${tablet} {
    gap: 24px;
  }
`;

const Subtitle = styled.h3`
  font-family: ${getFontFamily('bold')};
  font-size: 16px;
  line-height: 22px;

  ${tablet} {
    font-size: 18px;
    line-height: 26px;
  }
`;

const Warning = styled.div`
  flex-shrink: 0;
  font-size: 24px;
  line-height: 32px;
  padding: 8px 16px;
  border-radius: 999px;
  background-color: ${ColorService.YELLOW};
  font-family: ${getFontFamily('bold')};
  width: max-content;
  text-transform: uppercase;

  ${tablet} {
    font-size: 32px;
    line-height: 42px;
  }
`;

const AdditionalText = styled.div`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 64px;

  ${tablet} {
    margin-bottom: 80px;
  }
`;

const FormWrapper = styled.div`
  display: none;

  ${desktop} {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    align-self: flex-start;
    flex-direction: column;
    gap: 12px;
    top: 54px;
  }
`;

const ContentForm = styled.div`
  margin-bottom: 64px;

  ${tablet} {
    margin-bottom: 80px;
  }

  ${desktop} {
    display: none;
    margin-bottom: 0;
  }
`;
